:root {
  --clr-white: #ffffff;
  --clr-white-soft: #f8f8f8;
  --clr-white-mute: #f2f2f2;

  --clr-black: #181818;
  --clr-black-soft: #222222;
  --clr-black-mute: #282828;

  --clr-indigo: #2c3e50;
  --clr-main: #033b7d;
  --clr-sub: #f7f8f7;

  // --fil-white: 

  --shadow: 0 0 10px rgba(0, 0, 0, .9);
  --fil-main: brightness(0) saturate(100%) invert(15%) sepia(68%) saturate(2392%) hue-rotate(199deg) brightness(96%) contrast(99%);

  // semantic color variables
  --clr-divider-light-1: rgba(60, 60, 60, 0.29);
  --clr-divider-light-2: rgba(60, 60, 60, 0.12);
  --clr-divider-dark-1: rgba(84, 84, 84, 0.65);
  --clr-divider-dark-2: rgba(84, 84, 84, 0.48);

  --clr-text-light-1: var(--clr-indigo);
  --clr-text-light-2: rgba(60, 60, 60, 0.66);
  --clr-text-dark-1: var(--clr-white);
  --clr-text-dark-2: rgba(235, 235, 235, 0.64);

  --clr-background: var(--clr-white);
  --clr-background-soft: var(--clr-white-soft);
  --clr-background-mute: var(--clr-white-mute);

  --clr-border: var(--clr-divider-light-2);
  --clr-border-hover: var(--clr-divider-light-1);

  --clr-heading: var(--clr-text-light-1);
  --clr-text: var(--clr-text-light-1);

  --sys-width: 1280px;
  --prod-width: 1200px;
  --main-width: 1000px;


  --menu-height-pc: 80px;
  --menu-height-mb: 54px;
}
@primary-color: #038fde;
html {
  font-size: 62.5%;
}

body {
  position: relative;
  color: #333;
  // font-family: "Noto Serif JP", "HiraKakuProN-W3", "Meiryo", sans-serif;
  font-family: 'Noto Sans JP', 'microsoft yahei', sans-serif;

  margin: 0;
  -webkit-text-size-adjust: 100%;

  &.fixed {
    position: fixed;
  }
}


p,
h1,
h2 {
  margin: 0;
}

li {
  list-style: none;
}

i {
  font-style: normal;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  vertical-align: top;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 12px;
  color: var(--clr-main);
  background: var(--clr-back);
  overflow: hidden;
}


.fj {
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", 'Noto Serif JP', "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}


.ant-select-selector,
.ant-input-number-lg {
  border-radius: 0 !important;

  span {
    font-size: 13px;
    text-align: center;
    color: #666;
  }

}


@keyframes stroke-dashoffset {
  100% {
    stroke-dashoffset: 0;
  }
}


// @media screen and (max-width: 768px) {
//   #root {
//     width: 100vw;
//   }
// }
@primary-color: #038fde;